import React, { ReactElement } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../layouts/Layout/Layout'
import './AboutUsOffice.scss'
import { RecentArticleTypes } from '../../layouts/ArticleLayout/ArticleLayout'
import ContactUsBanner from '../../assets/images/contact-us-banner.png'
import ABOUT_US_NAVIGATION from '../../data/about-us-navigation.json'
import Seo from '../../components/Seo/Seo'
import { MediaTypes } from '../../components/MediaGallery/MediaGallery'
import loadable from '@loadable/component'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const ArticleLayout = loadable(() =>
  import('../../layouts/ArticleLayout/ArticleLayout'),
)

const SatteliteOffice = loadable(() =>
  import('../../components/SatteliteOffice/SatteliteOffice'),
)

const HeadOffice = loadable(() =>
  import('../../components/HeadOffice/HeadOffice'),
)

type ShowroomType = {
  id: string
  showRoomTitle: string
  images: MediaTypes[]
  showroomList?: {
    items?: {
      address: string
      telephoneHotline: string
      telephoneNumber: string
      title: string
    }[]
  }
  type: string
}

type AboutUsOfficePropTypes = {
  data: {
    allHeadOffice: {
      nodes: ShowroomType[]
    }
    allSatelliteOffice: {
      nodes: ShowroomType[]
    }
    recentArticles: {
      nodes: RecentArticleTypes[]
    }
  }
}

const AboutUsOffice = ({
  data: {
    allHeadOffice: { nodes: allHeadOffice },
    allSatelliteOffice: { nodes: allSatelliteOffice },
    recentArticles: { nodes: recentArticles },
  },
}: AboutUsOfficePropTypes): ReactElement => {
  const getBanner = (): string => {
    if (allHeadOffice) {
      return allHeadOffice[0]?.images
        ? allHeadOffice[0]?.images[0]?.url
        : ContactUsBanner
    }

    return ContactUsBanner
  }

  return (
    <Layout>
      <Seo
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/where-we-are/`,
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, about us, offices',
        }}
        title="About Us | Offices"
      />
      <ArticleLayout
        title="About Us"
        asideArticleTitle="News & Events"
        asideNavItems={ABOUT_US_NAVIGATION}
        breadcrumbs={[
          {
            label: 'Where we are',
          },
        ]}
        recentArticles={recentArticles}
        contentClassName="about-us-office"
      >
        <LazyLoadImage
          src={getBanner()}
          alt="banner image"
          className="about-us-office-banner"
        />
        <div className="about-us-office-content-container">
          {allHeadOffice.map((headOffice) => (
            <HeadOffice {...headOffice} />
          ))}
          <h2 className="about-us-office-title">Satellite Office</h2>
          {allSatelliteOffice.map((satelliteOffice) => (
            <SatteliteOffice {...satelliteOffice} />
          ))}
        </div>
      </ArticleLayout>
    </Layout>
  )
}

export default AboutUsOffice

export const pageQuery = graphql`
  query {
    allHeadOffice: allShowroom(
      filter: { type: { eq: "head-office-content" } }
    ) {
      nodes {
        ...ShowroomFields
      }
    }
    allSatelliteOffice: allShowroom(
      filter: { type: { eq: "satellite-office-content" } }
    ) {
      nodes {
        ...ShowroomFields
      }
    }
    recentArticles: allNewsAndEvents(limit: 3) {
      nodes {
        ...NewsAndEventsCardFields
      }
    }
  }
`
